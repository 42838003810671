<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <ActivityTypeForm
      v-if="!isLoading"
      :activityType="activityType"
      :imageSrc="imageSrc"
      v-on:addOrUpdateActivityType="addOrUpdateActivityType()"
      :submitName="$t('add')"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "@/utils/create-toast-mixin";
import PreLoader from "@/components/general/PreLoader.vue";
import { STATUS } from "@/utils/constants";
import DEFAULT_IMG from "@/assets/images/activity-types.svg";
// import { numberToEn } from "@/utils/functions";
import ActivityTypeForm from "@/components/activityTypes/ActivityTypeForm.vue";
import ActivityType from "@/models/activityTypes/ActivityType";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ActivityTypeForm,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  data() {
    return {
      isLoading: false,
      language: localStorage.getItem("userLanguage") || "ar",
      imageSrc: DEFAULT_IMG,
      activityType: new ActivityType(),
    };
  },
  methods: {
    async addOrUpdateActivityType() {
      this.isLoading = true;
      try {
        const response = await this.activityType.addOrUpdateActivityType(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.activityType.setInitialValue();
          this.showMsg(response.data.msg, true);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
